import React from "react";
import incode8_logo from '@assets/img/logo/incode8_logo.png';
import {NavLink, Link} from 'react-router-dom'
import {
  MdBusiness, MdDashboard, MdInsertChartOutlined, MdStar, MdOutlineCampaign, MdSend, MdAlignHorizontalLeft,
  MdKeyboardArrowDown, MdSupportAgent, MdAccountCircle, MdMobileFriendly, MdNotificationsNone, MdQuestionAnswer,
  MdOutlineFeed, MdSettingsEthernet, MdOutlineFavoriteBorder, MdPostAdd, MdEmail, MdCategory, MdOutlineFilterAlt,
  MdOutlineMonetizationOn, MdOutlineLocationOn, MdOutlineExtension, MdOutlineHotelClass, MdOutlineArticle, MdList,
  MdArrowCircleDown, MdOutlineFormatListNumbered, MdOutlineCategory, MdOutlinePayments, MdOutlineGrade,
  MdOutlineCreateNewFolder, MdManageAccounts, MdOutlinePictureAsPdf
} from 'react-icons/md'
import {BsJournalText, BsBoxSeam, BsTicket} from 'react-icons/bs'
import {BiSend, BiUserPlus} from 'react-icons/bi'
import {VscSymbolKeyword} from 'react-icons/vsc'
import {AiOutlineBook, AiOutlineForm, AiOutlineShop, AiOutlineKey} from 'react-icons/ai'
import {FiMail, FiShoppingBag, FiMonitor} from 'react-icons/fi';
import {CgDollar} from 'react-icons/cg';
import {GiCargoCrate} from 'react-icons/gi';
import bemNames from "@utils/bemnames";
import {Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink} from 'reactstrap'
import {FaRegListAlt} from "react-icons/fa";
import {GrGroup} from "react-icons/gr";

const bem = bemNames.create('sidebar');

const navMarketData = [{to: '/manage-market-data', name: '마켓데이터', exact: true, Icon: MdOutlineArticle}]
const navDashboard = [{ to: '/dashboard', name: '대시보드', exact: true, Icon: MdDashboard },];
const navSupport = [
  { to: '/manage-service/demo-apply', name: '데모 신청 관리', exact: true, Icon: FaRegListAlt  },
  { to: '/manage-service/contents-studio', name: '생성 콘텐츠 관리', exact: true, Icon: MdOutlineCreateNewFolder },
  { to: '/manage-support/notice', name: '공지사항', exact: true, Icon: MdNotificationsNone },
  { to: '/manage-support/faq', name: 'FAQ', exact: true, Icon: MdSupportAgent },
  { to: '/manage-support/question', name: '1:1 문의', exact: true, Icon: MdQuestionAnswer },
  { to: '/manage-support/terms', name: '이용약관/서비스정책', exact: true, Icon: MdOutlineFeed },
];
const navUser = [
  { to: '/manage-user/auth-e-commerce', name: 'E 커머스 인증 기록', exact: true, Icon: AiOutlineShop },
  { to: '/manage-user/auth-sns-platform', name: 'SNS 인증 기록', exact: true, Icon: MdMobileFriendly },
  { to: '/manage-user/user', name: '회원 리스트 관리', exact: true, Icon: MdManageAccounts },
];
const navProduct = [
  {to: '/manage-product/monitoring', name: '모니터링 관리', exact: true, Icon: FiMonitor },
  {to: '/manage-product/product', name: '상품 관리', exact: true, Icon: BsBoxSeam },
  {to: '/manage-product/keyword', name: '키워드 관리', exact: true, Icon: VscSymbolKeyword }
]
const navCampaign = [
  { to: '/manage-campaign', name: '캠페인', exact: true, Icon: MdOutlineCampaign },
];
const navStats = [
  { to: '/stats/influencer', name: '왕홍 통계', exact: true, Icon: MdStar },
  { to: '/stats/brand', name: '브랜드 통계', exact: true, Icon: MdBusiness },
  { to: '/stats/campaign', name: '캠페인 통계', exact: true, Icon: MdOutlineCampaign },
];
const navContents = [
  { to: '/manage-contents/category-mapping', name: '게시글 카테고리 메핑', exact: true, Icon: MdSettingsEthernet },
  { to: '/manage-contents/post-like-collect', name: '게시글 좋아요/찜하기', exact: true, Icon: MdOutlineFavoriteBorder },
  { to: '/manage-contents/group-buying', name: '공동구매 리스트', exact: true, Icon: GrGroup },
];
const navFilter = [
  { to: '/manage-filter/sns-platform', name: 'SNS 플랫폼 관리', exact: true, Icon: MdEmail },
  { to: '/manage-filter/shopping-mall-platform', name: '쇼핑몰 플랫폼', exact: true, Icon: AiOutlineShop},
  { to: '/manage-filter/category', name: 'SNS 카테고리 관리', exact: true, Icon: MdCategory },
  { to: '/manage-filter/shopping-mall-category', name: '쇼핑몰 카테고리 관리', exact: true, Icon: MdOutlineCategory },
  { to: '/manage-filter/user-level', name: '회원 등급 관리', exact: true, Icon: MdOutlineHotelClass },
  { to: '/manage-filter/user-type', name: '회원 타입 관리', exact: true, Icon: MdOutlineExtension },
  { to: '/manage-filter/influencer-grade', name: '인플루언서 등급 관리', exact: true, Icon: MdOutlineGrade},
  { to: '/manage-filter/contents-studio-type', name: '생성 컨텐츠 타입 관리', exact: true, Icon: MdList},
  { to: '/manage-filter/keyword', name: '키워드 관리', exact: true, Icon: VscSymbolKeyword},
  { to: '/manage-filter/crawling-keyword-type', name: '수집 키워드 타입', exact: true, Icon: AiOutlineKey },
  { to: '/manage-filter/range-follower', name: '팔로워 범위 관리', exact: true, Icon: MdAlignHorizontalLeft },
  { to: '/manage-filter/currency', name: '통화 관리', exact: true, Icon: MdOutlineMonetizationOn },
  { to: '/manage-filter/country', name: '국가 관리', exact: true, Icon: MdOutlineLocationOn },
  { to: '/manage-filter/industry', name: '업태 관리', exact: true, Icon: BiUserPlus},
  { to: '/manage-filter/hscode', name: 'hscode 관리', exact: true, Icon: GiCargoCrate },
  { to: '/manage-filter/ticket', name: '상품 티켓 관리', exact: true, Icon:  BsTicket},
  // { to: '/manage-filter/bank', name: '은행 관리', exact: true, Icon: BsBank2 },
];
const navTranslation = [
  { to: '/manage-translation/keyword', name: '번역 키워드 관리', exact: true, Icon: BsJournalText },
];
const navCrawling = [
  { to: '/manage-crawling/alc-crawling-status', name: '우선수집 수집현황', exact: true, Icon: MdPostAdd },
];
const navPayment = [
  { to: '/manage-payment/payment-history', name: '결제내역 조회', exact: true, Icon: MdOutlinePayments  },
];
const navMail = [
  { to: '/manage-mail/send-mail', name: '자동/수동 발신', exact: true, Icon: BiSend },
  { to: '/manage-mail/send-mail-list', name: '발신 기록', exact: true, Icon: MdOutlineFormatListNumbered },
  { to: '/manage-mail/mail-form', name: '양식 관리', exact: true, Icon: AiOutlineForm },
];
const navInvoice = [
  { to: '/invoice', name: '인보이스', exact: true, Icon: MdOutlinePictureAsPdf },
];

class Sidebar extends React.Component {
  state = {
    isOpenSupport: false,
    isOpenUser: false,
    isOpenCampaign: false,
    isOpenStats: false,
    isOpenContents: false,
    isOpenFilter: false,
    isOpenTranslation: false,
    isOpenCrawling: false,
    isOpenPayment: false,
    isOpenMail: false,
    isOpenProduct: false,
  };
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e('content')}>
          <Navbar>
            <Link to='/dashboard'>
              <img
                src={incode8_logo}
                width='140'
                className="pr-2"
                alt='incode8 Logo'
              />
            </Link>
          </Navbar>
          <Nav vertical>
            {navDashboard.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Support')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSupportAgent className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">서비스 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenSupport
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSupport}>
              {navSupport.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('User')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdAccountCircle className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">회원 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenUser
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenUser}>
              {navUser.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Product')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FiShoppingBag className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">EC커머스</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenProduct
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenProduct}>
              {navProduct.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            {navCampaign.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {navMarketData.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Stats')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdInsertChartOutlined className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">통계 자료</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenStats
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenStats}>
              {navStats.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">콘텐츠 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Filter')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdOutlineFilterAlt className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">필터 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenFilter
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenFilter}>
              {navFilter.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Translation')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <AiOutlineBook className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">번역 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenTranslation
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenTranslation}>
              {navTranslation.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Crawling')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdArrowCircleDown className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">수집 관리</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenCrawling
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenCrawling}>
              {navCrawling.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Payment')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <CgDollar className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">결제</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPayment
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPayment}>
              {navPayment.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Mail')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <FiMail className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">메일</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenMail
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenMail}>
              {navMail.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
            {navInvoice.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    )
  }
}

export default Sidebar